<template>
    <div class="content">
        <div class="search">
            <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="场内预付记录" :fetch="createExportData" :before-finish="finishDownload" worksheet="场内预付记录">
                <el-button type="primary" class="el-icon-download"> 导出</el-button>
            </JsonExcel>
            <el-button type="primary" class="el-icon-upload2" @click='refreshUpload' style="margin-left: 10px;"> 重新上传</el-button>
        </div>

        <el-card style="margin-top:15px;width:100%" class="box-card">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="search">
                        <label style="width: 100px;">预付时间：</label>
                        <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <div class="search">
                        <label>车牌号：</label>
                        <el-input v-model="searchForm.plateno" class="marg" style="width:150px"></el-input>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <div v-if="User.user_type!==5" class="search">
                        <label>车场：</label>
                        <el-select v-model="searchForm.pk_id" style="width:150px" @change="parkChanged">
                        <el-option v-if="User.user_type===5" label="全部" :value="0"></el-option>
                        <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                        </el-select>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="8" :md="6" :lg="2">
                <div class="search">
                    <el-button type="primary" plain @click="Search">查询</el-button>
                </div>
                </el-col>
            </el-row>
        </el-card>

          <el-table :data='DataList' border style="width: 100%;font-size:13px" max-height='calc(100vh - 250px)' height='calc(100vh - 250px)' stripe :highlight-current-row='true' size="mini">
            <el-table-column v-if="User.user_type!==5" align="center" prop="park_name" label="所属车场"></el-table-column>
            <el-table-column align="center" prop="log_plateno" label="车牌号"></el-table-column>
            <el-table-column align="center" prop="log_in_time" label="入场时间">
                <template slot-scope="scope">
                    {{FormData(scope.row.log_in_time)}}
                  </template>
            </el-table-column>
            <el-table-column align="center" prop="log_charge_time" label="预付时间" min-width="145px">
                <template slot-scope="scope">
                  {{FormData(scope.row.log_charge_time)}}
                </template>
            </el-table-column>
           
            <el-table-column align="center" prop="log_charge" label="预付金额(元)"></el-table-column>
          </el-table>
          <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @current-change="current_change" @size-change="handleSizeChange" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>
  
    </div>
</template>

<script>
import util from '../../../util/util'
import JsonExcel from 'vue-json-excel'

    export default{
        data:function(){
            return{
                User:null,
                searchForm:{
                    pageNo:1,
                    pageSize:15,
                    total:0,
                    pk_id:0,
                    time:[],
                    plateno:'',
                },
                ParkDataList:[],
                DataList:[],

                exportDataStandard: {
                    所属车场: "park_name" ,
                    车牌号:'log_plateno',
                    入场时间: {
                        field: 'log_in_time',
                        callback: value => {
                        return this.FormData(value)
                        }
                    },
                    预付时间: {
                        field: 'log_charge_time',
                        callback: value => {
                        return this.FormData(value)
                        }
                    },
                    预付金额: "log_charge" 
                },
                exportData: []
            }
        },
        computed: {
            FormData () {
                return function (time) {
                    if(time===null||time===undefined||time==='')
                        return ''
                    else
                        return util.FormateDate(time, 2)
                }
            }
        },
        components: {
            JsonExcel
        },
        created(){
            this.User = util.JesToken()
            this.getParkDataList()
        },
        methods:{
            getParkDataList(){
                if(this.User.user_type===5){
                    this.GetDataList()
                }
                else{
                    util.Get('parkmanage/getparkdatalist').then(res => {
                        if (res.rpStatus === 10000) {
                            this.ParkDataList = res.list
                            if(res.list.length>0){
                                this.searchForm.pk_id=res.list[0].pk_id
                                this.GetDataList()
                            }
                        }
                    })
                }
            },
            parkChanged(value){
                this.searchForm.pk_id=value
                this.GetDataList()
            },
            GetDataList () {
                if (this.searchForm.time === null || this.searchForm.time.length === 0) {
                    this.searchForm.begintime = ''
                    this.searchForm.endtime = ''
                } else {
                    this.searchForm.begintime = this.searchForm.time[0]
                    this.searchForm.endtime = this.searchForm.time[1]
                }
                util.Get('logprepay/getlogprepaydataList', this.searchForm).then(res => {
                    if (res.rpStatus === 10000) {
                        this.DataList = res.list
                        this.searchForm.total = res.total
                    }
                })
            },
            Search () {
                this.GetDataList()
            },
            current_change (page) {
                this.searchForm.pageNo = page
                this.GetDataList()
            },
            handleSizeChange(pageSize){
                this.searchForm.pageSize=pageSize
                this.GetDataList()
            },
            refreshUpload(){
                util.Send('refresh_upload_prepay_charge',this.searchForm.pk_id)
            },

            // 导出
            async createExportData () {
                this.searchForm.pageNo = 0
                var form = JSON.parse(JSON.stringify(this.searchForm))
                form.pageNo = 0

                var result = await util.Get('logprepay/getlogprepaydataList', form)
                if (result.rpStatus === 10000) {
                    this.exportData = result.list
                    return this.exportData
                }
            },
            finishDownload () {
                this.$message({
                    message: '导出数据成功',
                    type: 'success'
                })
                this.exportData=[]
            }
        }
    }
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.search label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
::v-deep.el-table thead {
  color: #666666;
  font-weight: 500;
}
</style>